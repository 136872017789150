import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Chip,
  ClickAwayListener,
  Input,
  LinearProgress,
  Modal,
  TablePagination,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseOutlined, KeyboardArrowRightRounded } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { usePrevious } from '@react-pdf-viewer/core';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from './styles';
import modalStyles from '../../assets/scss/modalWindow';
import { cardTypesForAllTable, searchTables } from '../../utils/constants/listsConstants';
import {
  INVALID_DATE,
  SEARCH_ALL,
  SEARCH_DEALS,
  SEARCH_FILTER_CURRENCIES,
  SEARCH_FILTER_DEAL_TYPES,
  SEARCH_FILTER_DEALS,
  SEARCH_FILTER_PRODUCTS,
  SEARCH_FILTER_REPORT_TYPES,
  SEARCH_FILTER_SELECTED_DATES,
  SEARCH_PDF_CONTENT,
  SEARCH_REPORTS,
  SEARCH_TOPICS,
  SEARCH_TYPE_DIRECT,
  SEARCH_TYPE_INDIRECT,
  SMALL_SIZE
} from '../../utils/constants/stringConstants';
import { getFormattedDate, onPressEnter } from '../../utils/utils';
import {
  searchDealsInfo,
  searchDocumentContent,
  searchDocumentContentValues,
  searchReportsInfo,
  searchTopicsInfo
} from '../../services/api/search';
import { showAlert } from '../../redux/actions';
import { trimValue } from '../../utils/common';
import ReportCard from './ReportCard';
import ReportContentCard from './ReportContentCard';
import DocumentContentCard from './DocumentContentCard';
import DealCard from './DealCard';
import TopicCard from './TopicCard';
import ElasticSearchFilter from './ElasticSearchFilter/ElasticSearchFilter';
import ElasticContext from './context/elastic.context';
import * as actionsCreator from './context/elastic.actions';
import { TablePaginationActions } from '../TablePaginationActions';
import { QUOTED_STRING_PATTERN } from '../../utils/constants/patterns';
import CustomButton from '../CustomButton/CustomButton';
import { formatDate } from '../../MainLayout/DealPageDetails/components/DealDetails/utils';
import { ELASTIC_CHIP_DATE_FORMAT } from '../../utils/constants/dateConstants';

const ElasticSearchModal = ({ open, onClose }) => {
  const classes = useStyles();
  const modalClasses = modalStyles();
  const {
    state: {
      searchResult,
      availableFilters,
      filterValues,
      keyCustomFields,
      loading,
      pagination,
      showFilterByProduct
    },
    dispatch
  } = useContext(ElasticContext);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [searchTable, setSearchTable] = useState(SEARCH_PDF_CONTENT);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isQuotedCriteria, setIsQuotedCriteria] = useState(false);
  const [searchApplied, setSearchApplied] = useState(false);
  const [cancelSources, setCancelSources] = useState([]);
  const dispatchRedux = useDispatch();
  const [formState, setFormState] = useState({
    filterValues: {
      products: [],
      dealTypes: [],
      deals: [],
      currencies: [],
      reportTypes: [],
      customFieldMap: new Map(),
      selectedDates: [null, null]
    },
    currentPage: {
      dealsInfo: 1,
      documentContent: 1,
      topicsInfo: 1,
      reportsInfo: 1
    },
    searchType: SEARCH_TYPE_DIRECT
  });
  const prevSearchTable = usePrevious(searchTable);
  const documentsRef = useRef(null);
  const dealsRef = useRef(null);
  const topicsRef = useRef(null);
  const reportsRef = useRef(null);
  const containerRef = useRef(null);
  const [overflowChips, setOverflowChips] = useState(0);
  const [showAllFilterChips, setShowAllFilterChips] = useState(false);
  const regex = new RegExp(QUOTED_STRING_PATTERN);

  const cancelRequests = () => {
    cancelSources.forEach((source) => source.cancel());
    setCancelSources([]);
  };

  const getRefByType = (cardType) => {
    switch (cardType) {
      case SEARCH_DEALS:
        return dealsRef;
      case SEARCH_PDF_CONTENT:
        return documentsRef;
      case SEARCH_TOPICS:
        return topicsRef;
      case SEARCH_REPORTS:
        return reportsRef;
      default:
        return null;
    }
  };

  const clearFormState = () => {
    setFormState(() => ({
      filterValues: {
        products: [],
        dealTypes: [],
        deals: [],
        currencies: [],
        reportTypes: [],
        customFieldMap: new Map(),
        selectedDates: [null, null]
      },
      currentPage: {
        dealsInfo: 1,
        documentContent: 1,
        topicsInfo: 1,
        reportsInfo: 1
      },
      searchType: SEARCH_TYPE_DIRECT
    }));
  };

  const clearFilters = () => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      filterValues: {
        products: [],
        dealTypes: [],
        deals: [],
        currencies: [],
        reportTypes: [],
        customFieldMap: new Map(),
        selectedDates: [null, null]
      }
    }));
  };

  const scrollToBlock = (blockRef) => {
    if (!!blockRef && blockRef.current) {
      blockRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleClose = () => {
    cancelRequests();
    dispatch(actionsCreator.clearState());
    clearFormState();
    setSearchValue('');
    setSearchPhrase('');
    dispatch(actionsCreator.setLoading(false));
    setSearchApplied(false);
    setShowAllFilterChips(false);
    onClose();
  };

  const getFilterValues = () => [
    filterValues.products,
    filterValues.currencies,
    filterValues.dealTypes,
    filterValues.deals,
    filterValues.reportTypes,
    filterValues.selectedDates && !filterValues.selectedDates.every((date) => date === null)
      ? filterValues.selectedDates
      : [],
    ...Array.from(filterValues.customFieldMap.values()).map((subArray) =>
      subArray.filter((value) => value !== null)
    )
  ];

  const countOfAppliedFilters = useMemo(() => {
    const filters = getFilterValues();
    return filters.reduce((count, filter) => count + (filter.length ? 1 : 0), 0);
  }, [filterValues]);

  const filterValuesChips = useMemo(() => {
    const filters = getFilterValues();
    return filters.flatMap((filter) => {
      if (Array.isArray(filter) && filter.length) {
        if (filter.every((item) => item instanceof Date)) {
          // Return the date range
          return `${formatDate(filter[0], ELASTIC_CHIP_DATE_FORMAT)}-${formatDate(
            filter[filter.length - 1],
            ELASTIC_CHIP_DATE_FORMAT
          )}`;
        }
        if (filter.every((item) => typeof item === 'object' && item.name)) {
          return filter.map((item) => item.name);
        }
      }
      return [];
    });
  }, [filterValues]);

  const updateNavigateUrl = (searchTableParam, searchValueParam, searchTypeParam) => {
    queryParams.set('globalSearchModal', 'true');
    queryParams.set('searchTable', searchTableParam);
    queryParams.set('searchType', searchTypeParam);
    queryParams.set('searchValue', searchValueParam);
    navigate({
      search: queryParams.toString()
    });
  };

  const handleSearchChange = ({ target }) => {
    cancelRequests();
    setSearchValue(target.value);
    dispatch(actionsCreator.setLoading(false));
  };

  const getPaginationSettingsName = (cardType) => {
    switch (cardType) {
      case SEARCH_DEALS:
        return 'dealsInfo';
      case SEARCH_PDF_CONTENT:
        return 'documentContent';
      case SEARCH_TOPICS:
        return 'topicsInfo';
      case SEARCH_REPORTS:
        return 'reportsInfo';
      default:
        return <div />;
    }
  };

  const handleChangePage = (newPage, cardType) => {
    const paginationName = getPaginationSettingsName(cardType);
    setFormState((prevFormState) => ({
      ...prevFormState,
      currentPage: {
        ...prevFormState.currentPage,
        [paginationName]: newPage
      }
    }));
    dispatch(actionsCreator.updatePaginationPage(paginationName, newPage));
  };

  const handleSearchTypeChange = ({ target }) => {
    handleChangePage(1, searchTable);
    setFormState((prevFormState) => ({
      ...prevFormState,
      [target.name]: target.value
    }));
  };

  const typeDirectSelected = useMemo(
    () => SEARCH_TYPE_DIRECT === formState.searchType,
    [formState.searchType]
  );

  const restoreFilter = () => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      filterValues
    }));
  };

  const updateFilterValues = (name, value, fieldId) => {
    if (name === 'customFieldMap') {
      setFormState((prevFormState) => {
        const updatedCustomFieldMap = new Map(prevFormState.filterValues.customFieldMap);
        if (
          updatedCustomFieldMap.has(fieldId) &&
          (value.length === 0 || value.every((item) => item === null))
        ) {
          updatedCustomFieldMap.delete(fieldId);
        } else {
          updatedCustomFieldMap.set(fieldId, value);
        }
        return {
          ...prevFormState,
          filterValues: {
            ...prevFormState.filterValues,
            customFieldMap: updatedCustomFieldMap
          }
        };
      });
    } else {
      setFormState((prevFormState) => ({
        ...prevFormState,
        filterValues: {
          ...prevFormState.filterValues,
          [name]: value
        }
      }));
    }
  };

  const getRequestParams = () => {
    const customFieldsMap = new Map(
      [...formState.filterValues.customFieldMap]
        .map(([key, values]) => {
          const keyField = keyCustomFields.get(key);
          if (keyField.type !== 'Date') {
            const fieldValues = values.map((item) => item.id);
            if (fieldValues.length > 0) {
              return [
                key,
                {
                  field: keyField.name,
                  operator: 'isAnyOf',
                  value: fieldValues
                }
              ];
            }
            return null;
          }
          const dateFrom = getFormattedDate(values[0]);
          const dateTo = getFormattedDate(values[1]);
          if (dateFrom !== INVALID_DATE && dateTo !== INVALID_DATE) {
            return [
              key,
              {
                field: keyField.name,
                operator: 'between',
                value: [dateFrom, dateTo]
              }
            ];
          }
          return null;
        })
        .filter(Boolean)
    );
    const customFieldsObject = Object.fromEntries(customFieldsMap);
    const dateFrom = getFormattedDate(formState.filterValues.selectedDates[0]);
    const dateTo = getFormattedDate(formState.filterValues.selectedDates[1]);
    return {
      search: searchPhrase,
      searchType: formState.searchType,
      productIds: formState.filterValues.products?.map((item) => item.id) || [],
      dealTypeIds: formState.filterValues.dealTypes?.map((item) => item.id) || [],
      dealIds: formState.filterValues.deals?.map((item) => item.id) || [],
      currencies: formState.filterValues.currencies?.map((item) => item.id) || [],
      reportTypes: formState.filterValues.reportTypes?.map((item) => item.id) || [],
      dateFrom: dateFrom !== INVALID_DATE ? dateFrom : '',
      dateTo: dateTo !== INVALID_DATE ? dateTo : '',
      ...(customFieldsMap.size > 0 && { customFields: customFieldsObject })
    };
  };

  const getURLParams = (cardType) => {
    if (SEARCH_ALL === searchTable) {
      return { page: 1, itemPerPage: 5 };
    }
    const paginationSettingsName = getPaginationSettingsName(cardType);
    if (SEARCH_PDF_CONTENT === searchTable) {
      return { page: formState.currentPage[paginationSettingsName], itemPerPage: 20 };
    }
    return { page: formState.currentPage[paginationSettingsName] };
  };

  const searchByType = async (searchMethod, cardType, resetValues) => {
    if (!searchPhrase || searchPhrase.length < 3) {
      return;
    }
    setSearchApplied(true);
    try {
      const source = axios.CancelToken.source();
      setCancelSources((prevSources) => [...prevSources, source]);
      const data = await searchMethod(getRequestParams(), getURLParams(cardType), source.token);
      const result = data?.result.map((item) => ({
        ...item,
        cardType,
        ...(cardType !== SEARCH_TOPICS && { values: item.values })
      }));
      dispatch(
        actionsCreator.getSearchResultSuccess(
          result,
          resetValues,
          getPaginationSettingsName(cardType),
          data.pageCounter,
          data.itemCounter,
          data.itemsPerPage
        )
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatchRedux(
          showAlert({
            isShown: true,
            type: 'error',
            message: error.response?.data?.message || error.response?.customData?.message
          })
        );
        dispatch(actionsCreator.setLoading(false));
      }
    }
  };

  const getFullDocumentContentValues = async (documentId) => {
    try {
      const source = axios.CancelToken.source();
      setCancelSources((prevSources) => [...prevSources, source]);
      const data = await searchDocumentContentValues(
        documentId,
        {
          search: searchPhrase,
          searchType: formState.searchType
        },
        source.token
      );
      dispatch(
        actionsCreator.getDocumentSearchResultSuccess({ ...data, cardType: SEARCH_PDF_CONTENT })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatchRedux(
          showAlert({
            isShown: true,
            type: 'error',
            message: error.response?.data?.message || error.response?.customData?.message
          })
        );
        dispatch(actionsCreator.setLoading(false));
      }
    }
  };

  const handleSearchTableChange = ({ target }) => {
    cancelRequests();
    setSearchApplied(false);
    setShowAllFilterChips(false);
    dispatch(actionsCreator.clearSearchResult());
    dispatch(actionsCreator.clearFilters());
    clearFormState();
    handleChangePage(1, searchTable);
    setSearchTable(target.value);
  };

  const getSearchMethodByCardType = (cardType) => {
    switch (cardType) {
      case SEARCH_DEALS:
        return searchDealsInfo;
      case SEARCH_PDF_CONTENT:
        return searchDocumentContent;
      case SEARCH_TOPICS:
        return searchTopicsInfo;
      case SEARCH_REPORTS:
        return searchReportsInfo;
      default:
        return null;
    }
  };

  const handleSearchByType = async (cardType, resetValues = false) => {
    try {
      await searchByType(getSearchMethodByCardType(cardType), cardType, resetValues);
    } catch (error) {
      if (error === 'Canceled') {
        throw error;
      }
    }
  };

  const performSearch = async () => {
    if (!!searchTable) {
      updateNavigateUrl(
        trimValue(searchTable),
        trimValue(searchPhrase),
        trimValue(formState.searchType)
      );
      dispatch(actionsCreator.setLoading(true));
      if (SEARCH_ALL === searchTable) {
        dispatch(actionsCreator.clearSearchResult());
        const promises = cardTypesForAllTable.map((cardType) =>
          handleSearchByType(cardType).catch(() => {})
        );

        await Promise.all(promises);
      } else {
        await searchByType(getSearchMethodByCardType(searchTable), searchTable, true);
      }
      dispatch(actionsCreator.setLoading(false));
    }
  };

  const applyFilter = (newFilterValues = formState.filterValues) => {
    dispatch(actionsCreator.updateFilters(newFilterValues));
    handleChangePage(1, searchTable);
    dispatch(actionsCreator.clearSearchResult());
    scrollToBlock(getRefByType(searchTable));
  };

  useEffect(() => {
    if (searchPhrase.length) {
      cancelRequests();
      dispatch(actionsCreator.clearSearchResult());
      handleChangePage(1, searchTable);
    }
  }, [searchPhrase]);

  useEffect(() => {
    if (searchPhrase.length && searchPhrase.length >= 3) {
      cancelRequests();
      if (SEARCH_ALL === searchTable && prevSearchTable === searchTable) {
        scrollToBlock(getRefByType(searchTable));
        dispatch(actionsCreator.clearSearchResult());
        performSearch();
      } else {
        const paginationSettingsName = getPaginationSettingsName(searchTable);
        if (formState.currentPage[paginationSettingsName] < 1) {
          return;
        }
        scrollToBlock(getRefByType(searchTable));
        dispatch(actionsCreator.clearSearchResult());
        performSearch();
      }
    }
  }, [formState.currentPage, formState.searchType]);

  useEffect(() => {
    if (open) {
      const searchParams = new URLSearchParams(location.search);
      const searchValueParam = searchParams.get('searchValue');
      if (!!searchValueParam) {
        setSearchValue(trimValue(searchValueParam));
        setSearchPhrase(trimValue(searchValueParam));
        setIsQuotedCriteria(regex.test(searchValueParam));
      }
      const searchTableParam = searchParams.get('searchTable');
      if (!!searchTableParam) {
        setSearchTable(searchTableParam);
      }
      const searchTypeParam = searchParams.get('searchType');
      if (!!searchTypeParam) {
        setFormState((prevFormState) => ({
          ...prevFormState,
          searchType: searchTypeParam
        }));
      }
    } else {
      setSearchValue('');
      setSearchPhrase('');
      dispatch(actionsCreator.setLoading(false));
      setSearchTable(SEARCH_PDF_CONTENT);
      dispatch(actionsCreator.clearState());
      clearFormState();
    }
  }, [open]);

  const renderCard = (cardType, data) => {
    switch (cardType) {
      case SEARCH_DEALS:
        return <DealCard data={data} searchValue={searchPhrase} />;
      case SEARCH_PDF_CONTENT:
        return (
          <DocumentContentCard
            data={data}
            searchValue={searchPhrase}
            getFullDocumentContentValues={getFullDocumentContentValues}
            isQuotedCriteria={isQuotedCriteria}
            typeDirectSelected={typeDirectSelected}
          />
        );
      case SEARCH_TOPICS:
        return <TopicCard data={data} searchValue={searchPhrase} />;
      case SEARCH_REPORTS:
        return data.pdfContent ? (
          <ReportContentCard
            data={data}
            searchValue={searchPhrase}
            isQuotedCriteria={isQuotedCriteria}
            typeDirectSelected={typeDirectSelected}
          />
        ) : (
          <ReportCard data={data} searchValue={searchPhrase} />
        );
      default:
        return <div />;
    }
  };

  const getContentBlock = (cardType, data) => {
    const paginationSettingsName = getPaginationSettingsName(cardType);

    return (
      <div
        className={clsx(
          classes.contentWithPagination,
          searchTable === SEARCH_ALL ? classes.contentAllWithNoHeight : ''
        )}
        ref={searchTable !== SEARCH_ALL ? getRefByType(cardType) : null}
      >
        {!loading && searchApplied && !searchResult.length && (
          <span className={classes.emptyResult}>No results</span>
        )}
        <div
          className={
            cardType === SEARCH_PDF_CONTENT || cardType === SEARCH_REPORTS
              ? classes.cardsList
              : classes.cardsTable
          }
        >
          {data.map((data) => renderCard(cardType, data))}
        </div>
        {SEARCH_ALL !== searchTable && !!data.length && (
          <div className={classes.pagination}>
            <TablePagination
              disabled={loading}
              className={classes.paginationBlock}
              rowsPerPageOptions={[]}
              component="div"
              labelDisplayedRows={() => ''}
              count={pagination[paginationSettingsName].itemCounter || 0}
              rowsPerPage={pagination[paginationSettingsName].itemPerPage}
              page={pagination[paginationSettingsName].page}
              onPageChange={(event, newPage) => handleChangePage(newPage, cardType)}
              ActionsComponent={(props) => (
                <TablePaginationActions
                  {...props}
                  size={SMALL_SIZE}
                  pageCounter={pagination[paginationSettingsName].pageCounter}
                />
              )}
            />
          </div>
        )}
      </div>
    );
  };

  const getCountOfResult = (cardType) => {
    const paginationSettingsName = getPaginationSettingsName(cardType);
    if (cardType !== SEARCH_ALL) {
      return pagination[paginationSettingsName].itemCounter || 0;
    }
    return (
      pagination.dealsInfo.itemCounter +
      pagination.documentContent.itemCounter +
      pagination.topicsInfo.itemCounter +
      pagination.reportsInfo.itemCounter
    );
  };

  const getContentBlockWithHeader = (cardType) => (
    <div className={classes.contentWithHeader} ref={getRefByType(cardType)}>
      <div className={classes.contentWithAllResultsHeader}>
        <div>{`${cardType} / ${getCountOfResult(cardType)}`}</div>
        {!!getCountOfResult(cardType) && (
          <CustomButton
            onClick={() => handleSearchTableChange({ target: { value: cardType } })}
            placeholder={`All ${cardType.toLowerCase()} results`}
            variant="text"
            size="medium"
            textColor="secondary"
            rightIcon={<KeyboardArrowRightRounded />}
            classes={classes}
          />
        )}
      </div>
      {getContentBlock(
        cardType,
        searchResult.filter((item) => item.cardType === cardType)
      )}
    </div>
  );

  const getContentBlockForAll = () => (
    <div className={classes.contentAll}>
      {getContentBlockWithHeader(SEARCH_TOPICS)}
      {getContentBlockWithHeader(SEARCH_PDF_CONTENT)}
      {getContentBlockWithHeader(SEARCH_DEALS)}
      {getContentBlockWithHeader(SEARCH_REPORTS)}
    </div>
  );

  const applyNewSearch = () => {
    if (trimValue(searchValue) !== searchPhrase) {
      setSearchPhrase(trimValue(searchValue));
      setIsQuotedCriteria(regex.test(searchValue));
    }
  };

  useEffect(() => {
    if (!filterValuesChips.length) {
      setShowAllFilterChips(false);
    }
    if (containerRef.current) {
      const container = containerRef.current;
      const allChips = Array.from(container.children);

      let totalWidth = 0;
      let visible = 0;
      let overflow = 0;
      allChips.forEach((chip) => {
        const chipWidth = chip.offsetWidth;
        if (!overflow && totalWidth + chipWidth <= container.offsetWidth) {
          visible += 1;
          totalWidth += chipWidth + 12;
        } else {
          overflow = filterValuesChips.length - visible;
        }
      });

      setOverflowChips(overflow);
      if (!overflow) {
        setShowAllFilterChips(false);
      }
    }
  }, [filterValuesChips]);

  const getFilterChip = (filterValue, removeFilter) => (
    <Chip
      variant="outlined"
      label={filterValue}
      onDelete={removeFilter}
      deleteIcon={
        <CloseIcon
          style={{
            width: '15px',
            height: '15px',
            color: '#111827'
          }}
        />
      }
      className={classes.chip}
    />
  );

  const removeFilterValue = (filterName, value, customFieldId) => {
    let newFilterValues;
    const updatedCustomFieldMap = new Map(filterValues.customFieldMap);

    if (customFieldId) {
      const keyField = keyCustomFields.get(customFieldId);
      const currentValues = updatedCustomFieldMap.get(customFieldId) || [];

      if (keyField?.type === 'Date') {
        newFilterValues = value;
      } else {
        newFilterValues = currentValues.filter((filterValue) => filterValue.id !== value);
      }

      if (!newFilterValues.length || newFilterValues.every((item) => item === null)) {
        updatedCustomFieldMap.delete(customFieldId);
      } else {
        updatedCustomFieldMap.set(customFieldId, newFilterValues);
      }
    } else if (Array.isArray(value)) {
      newFilterValues = value;
    } else {
      newFilterValues = filterValues[filterName]?.filter((filterValue) => filterValue.id !== value);
    }

    const updatedValues = {
      ...filterValues,
      [filterName]: customFieldId ? updatedCustomFieldMap : newFilterValues
    };

    updateFilterValues(filterName, newFilterValues, customFieldId);
    applyFilter(updatedValues);
  };

  const getAppliedFiltersChips = () => {
    const renderFilterChips = (filterName, formatter = (filterValue) => filterValue.name) => {
      const filters = filterValues[filterName];
      if (Array.isArray(filters) && filters.length) {
        return filters.map((filterValue) =>
          getFilterChip(formatter(filterValue), () => removeFilterValue(filterName, filterValue.id))
        );
      }
      return null;
    };

    const renderDateRangeChip = (dates, filterName, key = null) => {
      if (Array.isArray(dates) && dates.length && dates.every((item) => item instanceof Date)) {
        const startDate = formatDate(dates[0], ELASTIC_CHIP_DATE_FORMAT);
        const endDate = formatDate(dates[dates.length - 1], ELASTIC_CHIP_DATE_FORMAT);
        return getFilterChip(`${startDate}-${endDate}`, () =>
          removeFilterValue(filterName, [null, null], key)
        );
      }
      return null;
    };

    const renderCustomFieldChips = (isDateField) =>
      Array.from(filterValues.customFieldMap.keys()).flatMap((key) => {
        const values = filterValues.customFieldMap.get(key);
        if (Array.isArray(values) && values.length) {
          if (isDateField && values.every((item) => item instanceof Date)) {
            return renderDateRangeChip(values, 'customFieldMap', key);
          }
          if (!isDateField && values.every((item) => !(item instanceof Date))) {
            return values
              .filter((value) => value !== null)
              .map((filterValue) =>
                getFilterChip(filterValue.name, () =>
                  removeFilterValue('customFieldMap', filterValue.id, key)
                )
              );
          }
        }
        return [];
      });

    return (
      <ClickAwayListener
        onClickAway={() => showAllFilterChips && setShowAllFilterChips((prevValue) => !prevValue)}
      >
        <div className={classes.appliedFiltersGroup}>
          <div className={classes.appliedFiltersHeader}>Applied filters:</div>
          <div
            className={clsx(
              classes.chipsContainer,
              showAllFilterChips && classes.chipsContainerAll
            )}
            ref={containerRef}
          >
            {renderFilterChips(SEARCH_FILTER_PRODUCTS)}
            {renderFilterChips(SEARCH_FILTER_DEAL_TYPES)}
            {renderFilterChips(SEARCH_FILTER_CURRENCIES)}
            {renderFilterChips(SEARCH_FILTER_DEALS)}
            {renderFilterChips(SEARCH_FILTER_REPORT_TYPES)}
            {renderDateRangeChip(filterValues.selectedDates, SEARCH_FILTER_SELECTED_DATES)}
            {renderCustomFieldChips(false)}
            {renderCustomFieldChips(true)}
          </div>
          {overflowChips > 0 && (
            <Button
              variant={!showAllFilterChips ? 'text' : 'contained'}
              buttonColor="primary"
              size="medium"
              onClick={() => setShowAllFilterChips((prevValue) => !prevValue)}
              className={clsx(
                classes.showAllChipsButton,
                showAllFilterChips && classes.hideAllChipsButton
              )}
            >
              {!showAllFilterChips ? (
                `+${overflowChips}`
              ) : (
                <CloseIcon
                  style={{
                    width: '15px',
                    height: '15px',
                    color: '#FFFFFF'
                  }}
                />
              )}
            </Button>
          )}
        </div>
      </ClickAwayListener>
    );
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <div className={clsx(modalClasses.root, classes.modalForm)}>
        <div className={classes.header}>
          <CloseOutlined
            className={classes.closeIcon}
            sx={{ height: '20px', width: '20px' }}
            onClick={handleClose}
          />
          <div className={classes.searchWithFilter}>
            <div className={classes.searchGroupWithType}>
              <Input
                name="search"
                value={searchValue}
                placeholder="Search Dealscribe"
                className={classes.searchField}
                onChange={handleSearchChange}
                onKeyDown={onPressEnter(applyNewSearch)}
                onBlur={applyNewSearch}
              />
              <ToggleButtonGroup
                className={classes.searchTypeGroup}
                color="primary"
                value={formState.searchType}
                exclusive
                onChange={handleSearchTypeChange}
                aria-label="Platform"
              >
                <ToggleButton
                  name="searchType"
                  className={clsx(
                    classes.searchTypeButton,
                    typeDirectSelected && classes.typeSelected
                  )}
                  value={SEARCH_TYPE_DIRECT}
                >
                  Exact match
                </ToggleButton>
                <ToggleButton
                  name="searchType"
                  className={clsx(
                    classes.searchTypeButton,
                    !typeDirectSelected && classes.typeSelected
                  )}
                  value={SEARCH_TYPE_INDIRECT}
                >
                  Close match
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={classes.buttonGroup}>
              <ToggleButtonGroup
                disabled={loading}
                className={classes.searchTableGroup}
                color="primary"
                value={searchTable}
                exclusive
                onChange={handleSearchTableChange}
                aria-label="Platform"
              >
                {searchTables.map((type) => (
                  <ToggleButton
                    name="searchTable"
                    className={clsx(
                      classes.searchTableItem,
                      searchTable === type && classes.searchTableSelected
                    )}
                    value={type}
                  >
                    {type === searchTable && searchApplied && !loading
                      ? `${type} / ${getCountOfResult(searchTable)}`
                      : type}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              {searchTable !== SEARCH_ALL && (
                <ElasticSearchFilter
                  searchTable={searchTable}
                  loading={loading}
                  availableFilters={availableFilters}
                  filterValues={formState.filterValues}
                  appliedFilterValues={filterValues}
                  updateFilterValues={updateFilterValues}
                  performSearch={applyFilter}
                  keyCustomFields={keyCustomFields}
                  restoreFilter={restoreFilter}
                  countOfAppliedFilters={countOfAppliedFilters}
                  clearFilters={clearFilters}
                  showFilterByProduct={showFilterByProduct}
                />
              )}
            </div>
          </div>
          {!!countOfAppliedFilters && getAppliedFiltersChips()}
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            {loading && <LinearProgress />}
            {searchTable !== SEARCH_ALL && getContentBlock(searchTable, searchResult)}
            {searchTable === SEARCH_ALL &&
              (searchResult.length > 0
                ? getContentBlockForAll()
                : !loading &&
                  searchApplied && <span className={classes.emptyResult}>No results</span>)}
          </div>
        </div>
      </div>
    </Modal>
  );
};

ElasticSearchModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

ElasticSearchModal.defaultProps = {
  open: false
};

export default ElasticSearchModal;

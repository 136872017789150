import { createFilterOptions } from '@mui/material';
import { areArraysEqual } from 'src/utils/common';
import { SHARED_PORTFOLIO } from 'src/utils/constants/stringConstants';

export const getUsedPortfolio = (portfolioList, sharedPortfolioList, activePortfolio) => {
  const userPortfolio = portfolioList?.portfolio.find(
    (portfolio) => portfolio?.portfolioDto?.id === activePortfolio?.id
  );
  const sharedPortfolio = sharedPortfolioList?.portfolio.find(
    (portfolio) => portfolio?.portfolioDto?.id === activePortfolio?.id
  );

  return {
    usedPortfolio: userPortfolio || sharedPortfolio,
    isUserPortfolio: !!userPortfolio && !sharedPortfolio,
    isSharedPortfolio: !!sharedPortfolio && !userPortfolio
  };
};

export const reduceDocsUnderDealsWithCategoryTopics = (deals) =>
  deals.map((deal) => {
    const currentDocument = deal.documents.find((document) => document.isCurrent === true);
    const categoryTopics = currentDocument ? currentDocument.categoryTopics : null;
    const { documents, ...rest } = deal;
    return { ...rest, categoryTopics };
  });

export const rightTable = (deals, includeOnlyMajorDocuments) => {
  const majorDeals = deals.filter((deal) => !deal?.containsOnlyMinorDocuments);
  const minorDeals = deals.filter((deal) => deal?.containsOnlyMinorDocuments) || [];
  if (includeOnlyMajorDocuments) {
    return majorDeals;
  }
  return reduceDocsUnderDealsWithCategoryTopics([...majorDeals, ...minorDeals]);
};

export const filterOptions = createFilterOptions({
  stringify: (option) => option.name
});

export const setArrFromTopicsList = (portfolioList, sharedPortfolioList) => {
  const userPortfolio = [...portfolioList.portfolio].map((portfolio) => ({
    ...portfolio.portfolioDto,
    category: 'My portfolios'
  }));

  const sharedPortfolio = [...sharedPortfolioList.portfolio].map((portfolio) => ({
    ...portfolio.portfolioDto,
    category: SHARED_PORTFOLIO
  }));

  return [...userPortfolio, ...sharedPortfolio];
};

export const areCloIdsEqual = (state, prevState, portfolioId) => {
  const getCloIds = (portfolios) =>
    portfolios
      ?.find((portfolio) => portfolio?.portfolioDto.id === portfolioId)
      ?.cloDtoList.map((clo) => clo.id) || [];

  return areArraysEqual(getCloIds(state), getCloIds(prevState));
};

export const getAllTopics = (topicsState) => topicsState.flatMap(({ topics }) => topics);

export const groupTopicsComparison = (topicsState, topicGroups) => {
  const allTopics = getAllTopics(topicsState);
  const topicsMap = new Map(allTopics.map((topic) => [topic.id, topic]));
  return topicGroups.data
    .map((topicGroup) => {
      const topics = topicGroup.topicIds.map((topicId) => topicsMap.get(topicId)).filter(Boolean);
      return topics.length > 0
        ? {
            name: topicGroup.name,
            id: topicGroup.id,
            topics
          }
        : null;
    })
    .filter(Boolean);
};

export const getTopicAnswersForGroups = (categoryTopics) =>
  categoryTopics.flatMap(({ topicAnswers }) => topicAnswers);

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { overflowStyle } from 'src/utils/overflowStyle';

const ElipsisWrapper = ({ cellData, styles }) => {
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const itemRef = useRef(null);
  useEffect(() => {
    if (itemRef.current) {
      setIsEllipsisActive(itemRef.current.scrollWidth > itemRef.current.clientWidth);
    }
  }, [itemRef.current?.scrollWidth, cellData]);

  const tooltipTitle = isEllipsisActive ? cellData : '';
  return (
    <Tooltip disableInteractive title={tooltipTitle}>
      <span ref={itemRef} style={{ ...styles, ...overflowStyle }}>
        {cellData}
      </span>
    </Tooltip>
  );
};

ElipsisWrapper.propTypes = {
  cellData: PropTypes.object,
  styles: PropTypes.object
};

export default ElipsisWrapper;

import moment from 'moment';
import { DATE_TYPE } from '../../../../utils/constants/stringConstants';

const dateFormat = 'YYYY-MMM-DD';
export const formatDate = (value, format = dateFormat) => moment(value).format(format);

export const customFieldsValue = (type, value) => {
  if (type === DATE_TYPE && value) {
    return formatDate(value);
  }
  if (value || value === 0) {
    return value;
  }
  return '—';
};

import createReducer from 'src/utils/createReducer';
import * as constants from './deal.constants';
import { updatePortfolioStatus } from '../utils';

const initialSelectedFiltersState = {
  search: '',
  items: [],
  logicOperator: 'and'
};

const initialSelectedTopicsFilterState = {
  items: [],
  logicOperator: 'and'
};

export const initialState = {
  loadingDocuments: false,
  dealList: {
    deals: [],
    itemCounter: 0,
    itemsPerPage: 0,
    pageCounter: 0,
    sorting: '',
    availableFilters: {},
    loadDealsData: true
  },
  allDeals: [],
  allTopics: [],
  selectedFilters: { ...initialSelectedFiltersState },
  dealsOptionFilters: {
    includeDealsWithoutProduct: false,
    showPublicDeals: null
  },
  loading: false,
  scrolling: false,
  headerHeight: 0,
  error: null,
  files: [],
  uploadingFiles: false,
  successUpload: false,
  uploadError: null,
  search: '',
  dealsAvailableForPortfolio: {
    deals: [],
    loading: false
  },
  portfolioList: {
    portfolio: [],
    loading: true
  },
  sharedPortfolioList: {
    portfolio: [],
    loading: true
  },
  topics: {
    filters: { items: [], logicOperator: 'and' },
    portfolioFilters: {},
    documentFilters: {
      isCurrentDocument: true,
      excludeExpiredDeals: true,
      excludePrivateDocuments: true
    },
    availableFilters: {}
  },
  topicsList: [],
  topicData: {
    records: [],
    itemCounter: 0,
    itemsPerPage: 0,
    pageCounter: 0,
    sorting: '',
    filterUniqueValues: {}
  },
  portfolioTopics: [],
  products: {
    data: [],
    loading: false
  },
  updatedProducts: [],
  dealsForComparison: {
    data: []
  },
  comparisons: {
    data: []
  },
  frameLink: '',
  currentComparison: {},
  topicsGroups: {
    data: [],
    loading: false
  }
};

export default createReducer(initialState, {
  [constants.SAVE_FILES](state, { payload }) {
    return {
      ...state,
      uploadingFiles: true,
      files: payload,
      uploadError: null
    };
  },
  [constants.SAVE_FILES_SUCCESS](state, { payload }) {
    return {
      ...state,
      uploadingFiles: false,
      files: [],
      successUpload: !!payload
    };
  },
  [constants.SAVE_FILES_ERROR](state, { error }) {
    return {
      ...state,
      uploadingFiles: false,
      uploadError: error
    };
  },
  [constants.GET_ALL_DEALS](state, { payload }) {
    const data = [...state.allDeals, ...payload];
    const allDeals = [...new Map(data.map((item) => [item.id, item])).values()];
    return {
      ...state,
      allDeals
    };
  },
  [constants.GET_DEALS](state) {
    return {
      ...state,
      dealList: { ...state.dealList },
      loading: true
    };
  },
  [constants.GET_DEALS_SUCCESS](state, { payload }) {
    return {
      ...state,
      loading: false,
      dealList: { ...state.dealList, ...payload }
    };
  },
  [constants.ENABLE_SHOW_DEAL_PORTFOLIO_STATUS](state, { payload: { enabledDealIds } }) {
    const newDeals = updatePortfolioStatus(state.dealList.deals, enabledDealIds, 'id');
    return {
      ...state,
      loading: false,
      dealList: { ...state.dealList, deals: newDeals }
    };
  },

  [constants.ENABLE_SHOW_DEAL_PORTFOLIO_STATUS_TOPICS](state, { payload: { enabledDealIds } }) {
    const newTopics = updatePortfolioStatus(state.topicData.records, enabledDealIds, 'cloId');
    return {
      ...state,
      loading: false,
      topicData: { ...state.topicData, records: newTopics }
    };
  },

  [constants.LOAD_DEALS_DATA](state, { payload }) {
    return {
      ...state,
      dealList: { ...state.dealList, loadDealsData: payload }
    };
  },

  [constants.GET_DEAL_FILTERS_LIST](state) {
    return {
      ...state,
      dealList: { ...state.dealList, availableFilters: { ...state.availableFilters } },
      loading: true
    };
  },
  [constants.GET_DEAL_FILTERS_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      loading: false,
      dealList: { ...state.dealList, availableFilters: { ...payload } }
    };
  },
  [constants.GET_DEALS_ERROR](state, { error }) {
    return {
      ...state,
      loading: false,
      error
    };
  },

  [constants.SEARCH_DEAL](state, { payload }) {
    return {
      ...state,
      search: payload
    };
  },
  [constants.SEARCH_DEAL_SUCCESS](state) {
    return {
      ...state,
      search: '',
      loading: false
    };
  },
  [constants.SEARCH_DEAL_ERROR](state, { error }) {
    return {
      ...state,
      search: '',
      loading: false,
      error
    };
  },
  [constants.GET_PORTFOLIO_LIST](state) {
    return {
      ...state,
      portfolioList: { ...state.portfolioList, loading: true }
    };
  },
  [constants.GET_PORTFOLIO_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      portfolioList: { ...state.portfolioList, portfolio: payload, loading: false }
    };
  },
  [constants.DELETE_PORTFOLIO_FROM_LIST](state, { payload }) {
    const newPortfolioList = state.portfolioList.portfolio.filter(
      (item) => item.portfolioDto.id !== payload
    );

    return {
      ...state,
      portfolioList: { ...state.portfolioList, portfolio: newPortfolioList }
    };
  },
  [constants.EDIT_PORTFOLIO](state, { payload }) {
    const newPortfolioList = state.portfolioList.portfolio.map((item) =>
      item.id === payload.id ? payload : item
    );

    return {
      ...state,
      portfolioList: { ...state.portfolioList, portfolio: newPortfolioList }
    };
  },
  [constants.GET_DEALS_FOR_ADD_TO_PORTFOLIO](state) {
    return {
      ...state,
      dealsAvailableForPortfolio: {
        ...state.dealsAvailableForPortfolio,
        loading: true
      }
    };
  },

  [constants.GET_DEALS_FOR_ADD_TO_PORTFOLIO_SUCCESS](state, { payload }) {
    return {
      ...state,
      dealsAvailableForPortfolio: {
        ...state.dealsAvailableForPortfolio,
        deals: payload,
        loading: false
      }
    };
  },
  [constants.DELETE_DEAL_FROM_PORTFOLIO_LIST](state, { payload }) {
    const newPortfolioList = state.portfolioList.portfolio.map((item) => {
      const updatedCloDtoList =
        item.portfolioDto.id === payload.portfolioId
          ? item.cloDtoList.filter((deal) => deal.id !== payload.dealId)
          : item.cloDtoList;

      return {
        portfolioDto: {
          ...item.portfolioDto,
          product: updatedCloDtoList.length ? item.portfolioDto.product : null
        },
        cloDtoList: updatedCloDtoList
      };
    });

    return {
      ...state,
      portfolioList: { ...state.portfolioList, portfolio: newPortfolioList }
    };
  },
  [constants.ADD_PORTFOLIO](state, { payload }) {
    const newPortfolioList = [
      { portfolioDto: payload, cloDtoList: [] },
      ...state.portfolioList.portfolio
    ];
    return {
      ...state,
      portfolioList: { ...state.portfolioList, portfolio: newPortfolioList }
    };
  },

  [constants.GET_SHARED_PORTFOLIO_LIST](state) {
    return {
      ...state,
      sharedPortfolioList: { ...state.sharedPortfolioList, loading: true }
    };
  },
  [constants.GET_SHARED_PORTFOLIO_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      sharedPortfolioList: { ...state.sharedPortfolioList, portfolio: payload, loading: false }
    };
  },
  [constants.GET_TOPICS_LIST](state, { payload }) {
    return {
      ...state,
      topicsList: payload
    };
  },
  [constants.DELETE_ALL_TOPICS_FILTERS](state) {
    return {
      ...state,
      topics: {
        ...state.topics,
        filters: { ...initialSelectedTopicsFilterState }
      }
    };
  },
  [constants.GET_TOPICS_DATA](state, { payload }) {
    return {
      ...state,
      topicData: payload
    };
  },

  [constants.GET_ALL_TOPICS](state, { payload }) {
    const data = [...state.allTopics, ...payload];

    const allTopics = [...new Map(data.map((item) => [item.cloId, item])).values()];
    return {
      ...state,
      allTopics
    };
  },
  [constants.UPDATE_TOPICS_FILTERS](state, { payload }) {
    return {
      ...state,
      topics: {
        ...state.topics,
        filters: {
          items: [...payload.items] || [],
          logicOperator: payload.logicOperator,
          topicFilters: payload.topicFilters || []
        }
      }
    };
  },
  [constants.UPDATE_TOPICS_PORTFOLIO_FILTERS](state, { payload }) {
    return {
      ...state,
      topics: {
        ...state.topics,
        portfolioFilters: { ...payload }
      }
    };
  },
  [constants.UPDATE_TOPICS_DOCUMENT_FILTERS](state, { payload }) {
    return {
      ...state,
      topics: {
        ...state.topics,
        documentFilters: { ...state.topics.documentFilters, ...payload }
      }
    };
  },
  [constants.UPDATE_DEALS_OPTION_FILTERS](state, { payload }) {
    return {
      ...state,
      dealsOptionFilters: {
        ...state.dealsOptionFilters,
        ...payload
      }
    };
  },
  [constants.UPDATE_TOPICS_AVAILABLE_FILTERS](state, { payload }) {
    return {
      ...state,
      topics: { ...state.topics, availableFilters: payload }
    };
  },
  [constants.SELECT_OPTION_TO_SHOW_DEALS](state, { payload }) {
    return {
      ...state,
      selectedFilters: { ...state.selectedFilters, includedInPortfolio: payload }
    };
  },
  [constants.DOWNLOAD_TOPIC](state, { payload }) {
    return {
      ...state,
      loadingDocuments: payload
    };
  },
  [constants.UPDATE_DEALS_SELECTED_FILTERS](state, { payload }) {
    return {
      ...state,
      selectedFilters: { ...state.selectedFilters, ...payload }
    };
  },
  [constants.DELETE_ALL_DEALS_FILTERS](state) {
    return {
      ...state,
      selectedFilters: { ...initialSelectedFiltersState }
    };
  },
  [constants.DEAL_LIST_SEARCH](state, { payload }) {
    return {
      ...state,
      selectedFilters: { ...state.selectedFilters, search: payload }
    };
  },
  [constants.DEAL_LIST_SCROLL](state, { payload }) {
    return {
      ...state,
      scrolling: payload
    };
  },
  [constants.DEAL_HEADER_HEIGHT](state, { payload }) {
    return {
      ...state,
      headerHeight: payload
    };
  },
  [constants.LOAD_PRODUCTS](state) {
    return {
      ...state,
      products: { ...state.products, loading: true }
    };
  },
  [constants.GET_UPDATED_PRODUCTS](state, { payload }) {
    return {
      ...state,
      updatedProducts: payload
    };
  },
  [constants.LOAD_PRODUCTS_SUCCESS](state, { payload }) {
    return {
      ...state,
      products: { ...state.products, loading: false, data: payload }
    };
  },

  [constants.GET_USER_COMPARISONS](state, { payload }) {
    return {
      ...state,
      comparisons: { ...state.comparisons, data: payload }
    };
  },
  [constants.GET_DEALS_FOR_COMPARISON](state) {
    return {
      ...state,
      dealsForComparison: { ...state.dealsForComparison, loading: true }
    };
  },

  [constants.GET_DEALS_FOR_COMPARISON_SUCCESS](state, { payload }) {
    return {
      ...state,
      dealsForComparison: { ...state.dealsForComparison, data: payload, loading: false }
    };
  },
  [constants.GET_LINK_FOR_FRAME](state, { payload }) {
    return {
      ...state,
      frameLink: payload
    };
  },
  [constants.GET_CURRENT_COMPARISON](state, { payload }) {
    return {
      ...state,
      currentComparison: payload
    };
  },
  [constants.GET_TOPICS_GROUPS](state) {
    return {
      ...state,
      topicsGroups: { ...state.topicsGroups, loading: true }
    };
  },
  [constants.GET_TOPICS_GROUPS_SUCCESS](state, { payload }) {
    return {
      ...state,
      topicsGroups: { ...state.topicsGroups, data: payload, loading: false }
    };
  }
});

import React, { useEffect, useRef, useState } from 'react';
import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import { RemoveRedEyeOutlined, SourceRounded } from '@mui/icons-material';
import CustomTooltip from '../CustomTooltip';
import useStyles from './styles';
import { highlightTextWithTags } from '../../utils/common';
import { dealFieldsTitleMap, excludedFieldsMap } from './elasticConstants';
import { STATUS_PRIVATE } from '../../utils/constants/stringConstants';

const DealCard = ({ data }) => {
  const classes = useStyles();
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current) {
      setIsEllipsisActive(titleRef.current.scrollWidth > titleRef.current.clientWidth);
    }
  }, [data.reportName]);

  return (
    <div className={classes.card}>
      <div className={classes.cardTitleWithIcon}>
        <div className={classes.cardTitleGroup}>
          <SourceRounded />
          <CustomTooltip title={isEllipsisActive ? data.dealName : ''} placement="bottom-start">
            <Link
              className={classes.cardTitleWithWrap}
              href={`/deal/${data.dealId}`}
              underline="none"
              target="_blank"
              ref={titleRef}
            >
              {data.dealName}
            </Link>
          </CustomTooltip>
        </div>
        {data.status === STATUS_PRIVATE && <RemoveRedEyeOutlined />}
      </div>
      <div className={classes.cardContent}>
        {data.values.map(
          (value) =>
            !excludedFieldsMap.has(value.field) && (
              <div className={classes.cardContentRow}>
                <div className={classes.fieldName}>
                  {dealFieldsTitleMap.get(value.field) || value.field}:
                </div>
                <div className={classes.fieldValue}>{highlightTextWithTags(value.value)}</div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

DealCard.propTypes = {
  data: PropTypes.object
};

export default DealCard;

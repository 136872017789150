import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  noRecordsView: {
    textAlign: 'center',
    padding: '0px 24px 12px 24px'
  },
  linkToFavorites: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '25.6px',
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'auto',
    color: theme.palette.primary.light
  },
  noRecordsText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '25.6px',
    color: '#111111'
  }
}));

export default useStyles;

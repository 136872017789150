import React, { useEffect, useRef, useState } from 'react';
import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { highlightText, highlightTextWithTags } from '../../utils/common';
import useStyles from './styles';
import CustomButton from '../CustomButton/CustomButton';

const PageContent = ({ value, searchValue, url, isQuotedCriteria, typeDirectSelected }) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const contentRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (contentRef.current) {
      setIsEllipsisActive(contentRef.current.scrollHeight > 145);
    }
  }, []);

  return (
    <div className={classes.cardPdfContentRow}>
      <div ref={contentRef} className={clsx(classes.fieldValue, !isExpanded && classes.collapsed)}>
        {isQuotedCriteria && typeDirectSelected
          ? highlightText(value.value, searchValue)
          : highlightTextWithTags(value.value)}
      </div>
      <div className={classes.cardPdfContentFooter}>
        <Link className={classes.cardTitleWithWrap} href={url} underline="none" target="_blank">
          <div className={classes.fieldPage}>Page {value.field}</div>
        </Link>
        {isEllipsisActive && (
          <CustomButton
            onClick={toggleExpand}
            placeholder={isExpanded ? 'Show less' : 'Show more'}
            variant="text"
            size="medium"
            textColor="secondary"
            classes={classes}
          />
        )}
      </div>
      <span className={classes.divider} />
    </div>
  );
};

PageContent.propTypes = {
  value: PropTypes.object,
  searchValue: PropTypes.string,
  url: PropTypes.string,
  isQuotedCriteria: PropTypes.bool,
  typeDirectSelected: PropTypes.bool
};

export default PageContent;

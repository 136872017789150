import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import ElipsisWrapper from 'src/components/ElipsisWrapper';
import useStyles from './styles';

export const Column = ({ title, src, active }) => {
  const classes = useStyles();
  return (
    <Typography
      className={active ? clsx(classes.column, classes.blue) : classes.column}
      variant="body2"
    >
      {src && <img className={classes.icon} src={src} alt="icon" />}
      <ElipsisWrapper cellData={title} />
    </Typography>
  );
};

Column.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string,
  active: PropTypes.string
};

import { VirtualListBox, StyledPopper } from './VirtualListBox';

const buildAutocompleteVirtualizedProps = (itemSize) => ({
  PopperComponent: StyledPopper,
  renderOption: (props, option, state) => ({ props, option, state }),
  ListboxComponent: VirtualListBox,
  ListboxProps: { itemSize }
});

export { VirtualListBox, StyledPopper, buildAutocompleteVirtualizedProps };

import { DROPDOWN_INPUT_HEIGHT, DROPDOWN_INPUT_WIDTH } from 'src/utils/constants/numericConstants';

export const updateLimitTags = (handleLimitTags, inputRef, tagRefs) => () => {
  let totalWidth = 0;
  let visibleTags = 1;
  const inputWidth = inputRef.current ? inputRef.current.offsetWidth : DROPDOWN_INPUT_WIDTH;
  const inputHeight = inputRef.current ? inputRef.current.offsetHeight : DROPDOWN_INPUT_HEIGHT;
  tagRefs.current.forEach((tagRef) => {
    totalWidth += tagRef.offsetWidth;
    if (totalWidth <= inputWidth && inputHeight <= DROPDOWN_INPUT_HEIGHT) {
      visibleTags += 1;
    }
  });
  handleLimitTags(visibleTags);
};

import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useStyles from './styles';

const EmptyFavoritesView = () => {
  const classes = useStyles();
  return (
    <Typography className={classes.noRecordsText}>
      You have no groups of topics created.{' '}
      <Link
        className={classes.linkToFavorites}
        to="/deal/page/favorites?tab=topics"
        target="_blank"
      >
        Click here
      </Link>{' '}
      to create groups of topics.
    </Typography>
  );
};

export default EmptyFavoritesView;

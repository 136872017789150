import { makeStyles } from '@mui/styles';
import { LAPTOP_WIDTH } from 'src/utils/constants/numericConstants';

const useStyles = makeStyles((theme) => ({
  modalForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    maxWidth: '1360px !important',
    width: '100%  !important',
    height: '720px',
    padding: '0px 12px 12px 12px',
    border: '1px solid',
    borderColor: '#D8DBE0',
    backgroundColor: 'white',
    borderRadius: '16px',
    top: '56px',
    left: '50%',
    transform: 'translateX(-50%)',
    [theme.breakpoints.down(LAPTOP_WIDTH)]: {
      maxWidth: '1232px  !important',
      width: '100%  !important',
      top: '48px'
    }
  },
  header: {
    paddingTop: '12px',
    position: 'sticky',
    top: '0px',
    backgroundColor: 'white',
    zIndex: '10',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  searchWithFilter: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    paddingLeft: '12px',
    paddingRight: '12px'
  },
  closeIcon: {
    marginLeft: '12px',
    alignSelf: 'flex-end',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  contentWrapper: {
    height: '100vh',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      width: '10px',
      height: '10px',
      borderRadius: '200px',
      backgroundColor: '#A9A9A9'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '12px',
    paddingRight: '12px'
  },
  contentAll: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  contentWithPagination: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    height: 'calc(100vh - 440px)'
  },
  contentAllWithNoHeight: {
    height: 'unset'
  },
  pagination: {
    display: 'flex',
    justifyContent: 'end',
    gap: '10px',
    marginTop: 'auto'
  },
  paginationBlock: {
    '& .MuiTablePagination-displayedRows': {
      fontSize: '11px',
      fontWeight: '400',
      lineHeight: '13px',
      color: '#263238'
    },
    '& .MuiTablePagination-toolbar': {
      minHeight: '34px'
    }
  },
  contentWithHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  searchField: {
    width: '100%',
    color: '#111111',
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: '48px',
    '&:before': {
      borderBottom: '3px solid #D1D5DB'
    },
    '&:after': {
      borderBottom: '3px solid #D1D5DB'
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '3px solid #D1D5DB'
    }
  },
  buttonGroup: {
    height: '32px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  searchTableGroup: {
    height: '32px',
    gap: '24px',
    '& .MuiToggleButtonGroup-lastButton.Mui-disabled, & .MuiToggleButtonGroup-middleButton.Mui-disabled':
      {
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
      }
  },
  searchTableItem: {
    height: '32px',
    padding: '4px 16px',
    backgroundColor: '#F7F9FF',
    borderRadius: '5px !important',
    border: 'none',
    textTransform: 'none',
    color: '#111111',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '24px'
  },
  searchTableSelected: {
    backgroundColor: '#7F1D1D !important',
    color: '#FFFFFF !important'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '640px',
    border: '1px solid',
    borderColor: '#E5E7EB',
    borderRadius: '16px',
    padding: '12px 24px',
    gap: '4px'
  },
  cardFullWidth: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid',
    borderColor: '#E5E7EB',
    borderRadius: '16px',
    padding: '12px 24px',
    gap: '4px'
  },
  cardTitleGroup: {
    display: 'flex',
    width: 'calc(100% - 30px)',
    gap: '12px',
    fontSize: '20px',
    fontWeight: 900,
    lineHeight: '48px',
    color: '#374151',
    alignItems: 'center',
    '&:hover': {
      cursor: 'default'
    }
  },
  cardTitleWithIcon: {
    display: 'flex',
    gap: '5px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  reportCardHeaderGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  reportCardTitleGroup: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    maxWidth: '800px'
  },
  cardTitle: {
    color: '#374151',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '48px'
  },
  cardTitleWithWrap: {
    minWidth: '65px',
    fontSize: '20px',
    fontWeight: 700,
    color: '#374151',
    lineHeight: '48px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  cardsTable: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '22px',
    alignItems: 'flex-start'
  },
  cardsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '22px'
  },
  cardContentRow: {
    display: 'flex',
    gap: '8px',
    alignItems: 'start'
  },
  cardPdfContentRow: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  cardPdfContentFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: '24px'
  },
  cardPdfFooter: {
    display: 'flex',
    justifyContent: 'end'
  },
  fieldName: {
    color: '#9CA3AF',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '24px',
    '&:hover': {
      cursor: 'default'
    }
  },
  fieldPage: {
    color: '#7F1D1D',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    textDecoration: 'underline'
  },
  fieldValue: {
    color: '#000000',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    '&:hover': {
      cursor: 'default'
    }
  },
  fieldSecondary: {
    color: '#9CA3AF',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '24px'
  },
  collapsed: {
    maxHeight: '140px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  customButtonContent: {
    color: '#880101 !important'
  },
  searchValueStyle: {
    padding: '4px',
    borderRadius: '4px',
    fontStyle: 'italic',
    backgroundColor: '#FFDBDB'
  },
  reportType: {
    color: '#0A2705',
    fontWeight: 600,
    lineHeight: '21px',
    borderRadius: '8px',
    padding: '4px 12px 4px 12px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  },
  circleIcon: {
    width: '12px',
    height: '12px'
  },
  emptyResult: {
    color: '#374151',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '48px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '167px'
  },
  divider: {
    display: 'flex',
    width: '100%',
    height: '1px',
    border: '1px',
    opacity: '0.35px',
    backgroundColor: '#E5E7EB'
  },
  searchGroupWithType: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    alignItems: 'center'
  },
  searchTypeGroup: {
    height: '44px',
    width: '227px',
    backgroundColor: '#F3F4F6',
    borderRadius: '8px',
    padding: '3px 4px'
  },
  searchTypeButton: {
    textTransform: 'none',
    width: '110px',
    borderRadius: '8px !important',
    fontFamily: 'Nunito Sans, sans-serif',
    color: '#111827',
    fontWeight: 400,
    fontSize: '12px',
    border: 'none',
    letterSpacing: '0.15px'
  },
  typeSelected: {
    color: '#F9FAFB !important',
    backgroundColor: '#374151 !important',
    fontWeight: 600
  },
  contentWithAllResultsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  appliedFiltersGroup: {
    display: 'flex',
    marginLeft: '12px',
    marginRight: '24px',
    paddingLeft: '6px',
    gap: '12px',
    alignItems: 'center',
    paddingBottom: '8px',
    boxShadow: '0px 4px 4px 0px #11111112',
    borderRadius: '8px'
  },
  appliedFiltersHeader: {
    minWidth: '108px',
    color: '#111827',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px'
  },
  chipsContainer: {
    width: '1087px',
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '12px',
    rowGap: '8px',
    height: '31px',
    overflow: 'hidden'
  },
  chipsContainerAll: {
    height: '100%',
    overflow: 'unset'
  },
  chip: {
    height: '31px',
    backgroundColor: '#F3F4F6',
    borderRadius: '200px',
    color: '#111827',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    border: 'none'
  },
  showAllChipsButton: {
    width: '60px',
    height: '31px',
    color: '#111827',
    borderTopLeftRadius: '12px',
    borderBottomLeftRadius: '12px',
    backgroundColor: theme.palette.white,
    boxShadow: '-4px 4px 4px 0px #64646412'
  },
  hideAllChipsButton: {
    backgroundColor: '#7F1D1D'
  }
}));

export default useStyles;

/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Typography } from '@mui/material';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { FixedSizeList } from 'react-window';
import { overflowStyle } from 'src/utils/overflowStyle';

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const getListBoxItem =
  (mainNode) =>
  ({ index, style, data }) => {
    const itemRef = useRef(null);
    const [isEllipsisActive, setIsEllipsisActive] = useState(false);

    useEffect(() => {
      const item = itemRef.current;
      if (item) {
        setIsEllipsisActive(item.scrollWidth > item.clientWidth);
      }
    }, []);

    const item = data[index];

    const tooltipTitle = isEllipsisActive ? item.option.dealName || item.option.name : null;
    const content = item.option.dealId ? (
      <Typography ref={itemRef} style={style} noWrap {...item.props}>
        {item.option.dealName}
      </Typography>
    ) : (
      <Typography component="li" style={style} noWrap {...item.props}>
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={item.state.selected}
        />
        {!mainNode ? (
          <p ref={itemRef} style={overflowStyle}>
            {item.option.name}
          </p>
        ) : (
          mainNode({ itemRef, item, overflowStyle })
        )}
      </Typography>
    );

    return (
      <Tooltip disableInteractive title={tooltipTitle} placement="top">
        {content}
      </Tooltip>
    );
  };

export const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
});

export const VirtualListBox = React.forwardRef(
  ({ children, mainNode, itemSize, ...other }, ref) => {
    const itemData = [];
    children.forEach((child) => {
      itemData.push(child);
      itemData.push(...(child.children || []));
    });
    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <FixedSizeList
            overscanCount={20}
            height={320}
            width="100%"
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemData={itemData}
            itemSize={itemSize || 42}
            itemCount={itemData.length}
          >
            {getListBoxItem(mainNode)}
          </FixedSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

VirtualListBox.propTypes = {
  children: PropTypes.array,
  mainNode: PropTypes.array,
  itemSize: PropTypes.number
};

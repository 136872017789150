import axios from 'axios';

export const getTopicsGroups = async () => {
  const { data } = await axios.get('/group');
  return data;
};

export const createTopicsGroup = async (params) => {
  const { data } = await axios.post('/group', params);
  return data;
};

export const updateTopicsGroup = async (groupId, params) => {
  const { data } = await axios.put(`/group/${groupId}`, params);
  return data;
};

export const deleteTopicsGroup = async (groupId) => {
  const { data } = await axios.delete(`/group/${groupId}`);
  return data;
};

export const deleteTopicFromGroup = async (groupId, topicId) => {
  const { data } = await axios.delete(`/group/${groupId}/topic/${topicId}`);
  return data;
};

export const updateGroupsOrder = async (groupId, params) => {
  const { data } = await axios.put(`/group/${groupId}/topic/order`, params);
  return data;
};

import React from 'react';
import clsx from 'clsx';
import { Grid, Tooltip } from '@mui/material';
import Score from 'src/components/Score/Score';
import { isEqual } from 'lodash';
import { ALL_TOPICS } from 'src/MainLayout/PDFLayout/PDFTabs/TopicsTab/constants';
import { isNumber } from 'validate.js';
import { DealName } from './DealName';
import { Topic } from './TopicsForCompareTable';
import { getTopicAnswersForGroups } from '../ComparisonPage/utils';

const createColumn = (
  el,
  docIndex,
  styleFlag,
  classes,
  numberOfDeals,
  lastDealElementRef,
  currentTab,
  dealIndex = null
) => {
  const getHeaderStyle = () => {
    if (styleFlag === 'deal') {
      return docIndex % 2 === 0 ? classes.bgLightGray : classes.bgGray;
    }
    return dealIndex % 2 === 0 ? classes.bgLightGray : classes.bgGray;
  };
  const getBodyStyle = () => {
    if (dealIndex) {
      return dealIndex % 2 === 0 ? classes.bgSuperLightGray : classes.bgWhite;
    }
    return docIndex % 2 === 0 ? classes.bgSuperLightGray : classes.bgWhite;
  };

  return {
    field: el.name || el.dealName,
    renderHeader: () => (
      <DealName
        doc={el}
        name={el.name || el.dealName}
        lastDealElementRef={lastDealElementRef}
        numberOfDeals={numberOfDeals}
        index={dealIndex || docIndex}
      />
    ),
    minWidth: 350,
    sortable: false,
    order: el.position,
    cellClassName: ({ rowNode }) => (rowNode.children ? getHeaderStyle() : getBodyStyle()),
    // eslint-disable-next-line consistent-return
    headerClassName: () => getHeaderStyle(),
    renderCell: ({ row, rowNode }) => {
      const category = el.categoryTopics.find((c) => c.categoryScore.name === row.hierarchy[0]);
      if (row.topic) {
        const topic =
          currentTab === ALL_TOPICS
            ? category.topicAnswers.find((t) => t.topicId === row.topic.id)
            : getTopicAnswersForGroups(el.categoryTopics).find((t) => t.topicId === row.topic.id);

        return <Topic topic={topic} />;
      }
      return (
        rowNode.children &&
        isNumber(category?.categoryScore?.score) &&
        category?.categoryScore?.score !== null && (
          <Score type="categoryScore" mark={category?.categoryScore?.score} />
        )
      );
    }
  };
};

export const prepareColumns = (docs, classes, lastDealElementRef, currentTab) => {
  let columns;
  // empty columns for keeping UI consistent
  if (!docs.length) {
    const emptyCol = (fieldName) => ({
      field: fieldName,
      headerName: ' ',
      width: 350,
      sortable: false
    });
    columns = ['emptyCol1', 'emptyCol2', 'emptyCol3'].map((el) => emptyCol(el));
  } else {
    columns = docs?.map((deal, dealIndex) =>
      deal.dealName
        ? createColumn(
            deal,
            dealIndex,
            'deal',
            classes,
            docs.length,
            lastDealElementRef,
            currentTab
          )
        : deal.map((doc, docIndex) =>
            createColumn(
              doc,
              docIndex,
              'doc',
              classes,
              docs.length,
              lastDealElementRef,
              currentTab,
              dealIndex
            )
          )
    );
  }

  return [
    {
      field: 'topics',
      headerName: 'Topics',
      width: 200,
      minWidth: 150,
      sortable: false,
      cellClassName: ({ rowNode }) =>
        rowNode.children ? classes.categoryCol : classes.categoryColOpen,
      headerClassName: clsx(classes.categoryCol, classes.bgGray),
      renderCell: ({ row, rowNode }) => {
        const topic = rowNode.parent ? row.topic : '';
        return (
          <Tooltip
            disableInteractive
            title={`Question: ${topic?.question}`}
            placement="bottom-start"
          >
            <Grid>{topic?.name}</Grid>
          </Tooltip>
        );
      }
    },
    ...columns.flat()
  ];
};

export const getFilteredRows = (rowsToFilter) =>
  rowsToFilter.filter((row) => row.hierarchy.length === 1);

export const hasRowIdsChanged = (rows, prevRows) => {
  const prevFilteredRowIds = getFilteredRows(prevRows).map((row) => row.id);
  const filteredRowIds = getFilteredRows(rows).map((row) => row.id);
  return !isEqual(filteredRowIds, prevFilteredRowIds);
};

export const prepareRows = (leftTable, currentTab) => {
  const categoryRows =
    currentTab === ALL_TOPICS
      ? leftTable.map((category) => ({
          name: category.category,
          topics: category.topics,
          hierarchy: [category.category],
          id: category.id
        }))
      : leftTable.map((topicGroup) => ({
          name: topicGroup.name,
          topics: topicGroup.topics,
          hierarchy: [topicGroup.name],
          id: topicGroup.id
        }));

  const topicRows = categoryRows.map((row) =>
    row.topics.map((topic) => ({
      hierarchy: [row.name, topic.name],
      topic,
      id: `${row.name}-${topic.id}`
    }))
  );

  return [...categoryRows, ...topicRows.flat()];
};

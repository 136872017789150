import React, { useEffect } from 'react';
import { matchPath, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import UserPool from 'src/UserPool';
import { login, logout } from 'src/redux/actions';
import { getCurrentDealDocument } from 'src/services/api/deal';
import { ADMIN_ROLE, EDITOR_ROLE } from '../utils/constants/stringConstants';
import { buildUserObject } from './utils';

const permitPages = [
  '/home',
  '/auth/login',
  '/errors/error-404',
  '/errors/error-403',
  '/errors/error-401',
  '/errors/error-500'
];
const oldPages = '/deal-pdf/';

function AuthGuard({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatchRedux = useDispatch();

  const saveUser = (payload) => {
    dispatchRedux(login(buildUserObject(payload)));
  };

  const redirectOldUrl = async () => {
    const checkOldUrl = location.pathname.includes(oldPages);
    if (checkOldUrl) {
      const dealId = location.pathname.replace(/[^0-9]/g, '');
      if (!dealId) {
        return;
      }
      const data = await getCurrentDealDocument(dealId);
      if (location.search.includes('labelId')) {
        navigate(`/pdf/deal/${dealId}/document-pdf/${data}${location.search}`);
      } else navigate(`/pdf/deal/${dealId}/document-pdf/${data}`);
    } else if (matchPath({ path: '/deal/:deal', exact: true }, location.pathname)) {
      const dealId = location.pathname.replace(/[^0-9]/g, '');
      if (!dealId) {
        return;
      }
      const data = await getCurrentDealDocument(dealId);
      navigate(`/deal/${dealId}/document/${data}`);
    }
  };

  const checkPermissions = () => {
    if (permitPages.some((url) => url === location.pathname)) {
      return;
    }

    if (location.pathname === '/') {
      navigate('/home');
    } else {
      navigate('/auth/login');
    }
  };

  useEffect(() => {
    sessionStorage.setItem('prevLocation', location.pathname);
    if (location.pathname === '/auth/login') {
      dispatchRedux(logout());
    }

    const currentUser = UserPool.getCurrentUser();
    if (currentUser) {
      currentUser.getSession((error, activeSession) => {
        if (error) {
          dispatchRedux(logout());
          navigate('/auth/login');
        } else {
          currentUser.getUserData((err, res) => {
            if (
              location.pathname === '/operations' &&
              activeSession.getIdToken().payload['cognito:groups'][0] === ADMIN_ROLE
            ) {
              navigate('operations/topic-management');
            }
            if (
              location.pathname === '/operations' &&
              activeSession.getIdToken().payload['cognito:groups'][0] === EDITOR_ROLE
            ) {
              navigate('/operations/workflow');
            }
            if (res) {
              const checkUserEmailVerification = res.UserAttributes.some(
                (attr) => attr.Name === 'email_verified' && attr.Value === 'true'
              );
              if (checkUserEmailVerification) {
                saveUser(activeSession.getIdToken().payload);
                redirectOldUrl();
              } else {
                // this is a way to clear cached user data and get new one next time
                currentUser.setSignInUserSession(activeSession);
                checkPermissions();
              }
            }
          });
        }
      });
    } else {
      checkPermissions();
    }
  }, [location.pathname]);

  return <div>{children}</div>;
}

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
